import React, {Component} from 'react';
import style from './serviceSlider.module.scss';
import Slider from 'react-slick';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Arrow from './arrow'
import Image from 'shared/image';
import Icon from 'components/icon';
import parse from 'html-react-parser';

class ServiceSlider extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			leftDisabled: this.props.slide > 0 ? false : true,
			rightDisabled: false,
			currentSlide: this.props.slide,
		}
		this.node = null;
	}

	handleClick(direction) {
		if (direction === -1) this.slider.slickPrev();
		else this.slider.slickNext();
	}

	handleChange(slide) {
		const hasNext = (parseInt($(this.node).find('.slick-slide.slick-active').last().data('index')) < $(this.node).find('.slick-slide').length - 1 ? true : false);
		this.setState({
			leftDisabled: (slide === 0),
			rightDisabled: !hasNext,
		});
	}

	componentDidMount() {
		this.node = ReactDOM.findDOMNode(this);
		this.numSlides = this.props.services.length;
		this.slider.slickGoTo(this.props.slide);
	}

	render() {
		const services = this.props.services;
		const slide = this.props.slide;
		const slick = {
			dots: false,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			className: style.slick,
			arrows: false,
			ref: slider => (this.slider = slider),
			beforeChange: (prev, next) => {
				this.setState({ currentSlide: next });
			},
			afterChange: this.handleChange,
		}
		const leftDisabled = this.state.leftDisabled;
		const rightDisabled = this.state.rightDisabled;

		return (
			<section className={style.service} >
				<div className="grid-container">
					<div className={`${style.upper} grid-x grid-margin-x`} style={{ alignItems: "center" }}>
						<div className="cell small-12 medium-8">
							<div onClick={this.props.clickHandle} className={`${style.backtoservice}`}>{ this.props.closeText ? this.props.closeText : "Back To Services" }</div>
						</div>
						{this.numSlides > 1 && <div className={`${style.arrows} cell medium-offset-2 medium-2`}>
							<Arrow direction="left" title="Go to previous slide of events." onClick={this.handleClick} disabled={leftDisabled} />
							<div className={style.separator}>|</div>
							<Arrow direction="right" title="Go to next slide of events." onClick={this.handleClick} disabled={rightDisabled} />
						</div>}
					</div>
					<div className="grid-x grid-margin-x">
						<div className={`${style.sliderContainer} cell small-12`}>
							<Slider ref={slider => (this.slider = slider)} {...slick}>
								{services.map((service, index) => (
									<div key={index} className={style.slide}>
										<div className="grid-container">
											<div className="grid-x grid-margin-x">
												<div className={`${style.serviceDetails} cell medium-10 medium-offset-1`}>
													<div className={style.name}>{service.name}</div>
													<div className={service.description ? style.description : 'hide'}>{service.description && parse(service.description)}</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default ServiceSlider;