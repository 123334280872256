import React, { Component } from 'react'
import Accordion from 'components/accordion';
import style from './faqs.module.scss';

class FAQs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			faqs: this.props.data
		};
	}

	render() {
		return (
			<div className={style.container}>
				<div className="grid-container">
					<div className="grid-x">
						<div className="cell">
							<h3 className={style.title}>FAQs</h3>
						</div>
					</div>
					<div className="grid-x">
						{this.state.faqs.map((faq, index) => (
							<div className="cell small-12"><Accordion title={faq.question} body={faq.answer} /></div>
						))}
					</div>
				</div>
			</div>
		)
	}
}

export default FAQs;