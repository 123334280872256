import React, {Component} from 'react'
// Import neccessary site components, try to maintain alphabetical order
import FAQs from 'components/faqs';

class FAQsController extends Component {
	constructor(props) {
		super(props);

		this.data = [
			{
				question: 'Do you accept emergency or weekend appointments?',
				answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices eros magna, nec mollis tortor placerat et. Duis vitae gravida arcu, non sodales mi. Aenean bibendum est lacus, vel suscipit ipsum convallis in.',
			},
			{
				question: 'What type of services do you offer?',
				answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices eros magna, nec mollis tortor placerat et. Duis vitae gravida arcu, non sodales mi. Aenean bibendum est lacus, vel suscipit ipsum convallis in.',
			},
			{
				question: 'Do you vaccinate all children?',
				answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices eros magna, nec mollis tortor placerat et. Duis vitae gravida arcu, non sodales mi. Aenean bibendum est lacus, vel suscipit ipsum convallis in.',
			},
			{
				question: 'What do I do when my child is sick?',
				answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices eros magna, nec mollis tortor placerat et. Duis vitae gravida arcu, non sodales mi. Aenean bibendum est lacus, vel suscipit ipsum convallis in.',
			},
			{
				question: 'What if I have a question and the office is closed?',
				answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices eros magna, nec mollis tortor placerat et. Duis vitae gravida arcu, non sodales mi. Aenean bibendum est lacus, vel suscipit ipsum convallis in.',
			},
			{
				question: 'How often should I schedule wellness and preventive care visits for my child?',
				answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices eros magna, nec mollis tortor placerat et. Duis vitae gravida arcu, non sodales mi. Aenean bibendum est lacus, vel suscipit ipsum convallis in.',
			},
			{
				question: 'What do I do if I am pregnant?',
				answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices eros magna, nec mollis tortor placerat et. Duis vitae gravida arcu, non sodales mi. Aenean bibendum est lacus, vel suscipit ipsum convallis in.',
			}
		]
	}

	render() {
		return (
			<FAQs data={this.data} />
		)
	}
}

export default FAQsController;