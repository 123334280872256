import React, { Component, Fragment } from "react"
// import $ from 'jquery';
// import { Router } from "@reach/router"
import {graphql} from 'gatsby'
import Callout from 'components/specialties/callout';
import DepartmentNewsController from 'controllers/blog/departmentnews';
import FAQsController from 'controllers/faqs/list';
import Layout from 'components/layout/basic';
import LocationList from 'components/locations/list';
import Masthead from 'components/masthead/specialtybasic';
import PatientEducationController from 'controllers/blog/patienteducation';
import ProviderList from 'components/providers/list';
import ServicesListController from 'controllers/services/list';
import Services from 'components/services/';

import SEO from 'shared/seo';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

export default class PhysiciansSpecialtyGet extends Component {
	constructor(props) {
		super(props);
		this.specialty = (this.props.data.physiciansSpecialty ? this.props.data.physiciansSpecialty : null);
		this.allSpecialties = (this.props.data.allPhysiciansSpecialty && this.props.data.allPhysiciansSpecialty.edges.length ? this.props.data.allPhysiciansSpecialty.edges : []);
		this.allLocations = (this.props.data.allLocationsLocation && this.props.data.allLocationsLocation.edges.length ? this.props.data.allLocationsLocation.edges : []);
		this.allCategories = (this.props.data.allPhysiciansCategory && this.props.data.allPhysiciansCategory.edges.length ? this.props.data.allPhysiciansCategory.edges : []);
		this.allProviders = (this.props.data.allPhysiciansProvider && this.props.data.allPhysiciansProvider.edges.length ? this.props.data.allPhysiciansProvider.edges : []);
		let initialData = this.processData(this.specialty);	
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
			showHR: false,
		}

		this.liveRefresh = this.liveRefresh.bind(this);
		this.processData = this.processData.bind(this);
	}

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/specialty/get/?_join=true&uri=${this.specialty.uri}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let specialty = this.processData(data[0]);
					this.setState({
						status: STATUS.LOADED,
						data: specialty,
					})
				}
				console.log('Live refresh complete');
			})
			.catch(err => console.log);
	}

	processData(data) {
		if (!data) {
			return;
		}
		let allConditions = [];
		let allProcedures = [];
		let newDatum = {
			id: data.id,
			name: data.name,
			description: data.description,
			sidebar: data.sidebar,
			background: data.backgroundColor,
			sidebarImage: data.sidebarImage,
			tags: data.tags,
			callout: {},
			providers: [],
			locations: [],
			services: [],
		}
		// Parse out the callout, if there is one
		if (data.calloutBackgroundImage) {
			newDatum.callout.background = data.calloutBackgroundImage;
		}
		if (data.calloutBody) {
			newDatum.callout.body = data.calloutBody;
		}
		if (data.calloutImage) {
			newDatum.callout.image = data.calloutImage;
		}
		if (data.calloutTitle) {
			newDatum.callout.title = data.calloutTitle;
		}

		// Parse out the conditions
		if (data.Condition && data.Condition.length) {
			let conditions = [];
			data.Condition.forEach(condition => {
				let conditionsObj = {
					name: condition.object.name,
					description: condition.object.description,
				};
				conditions.push(conditionsObj);
			});
			if (conditions.length) {
				allConditions = conditions;
			} 
		}
		// Parse out the procedures
		if (data.Procedure && data.Procedure.length) {
			let procedures = [];
			data.Procedure.forEach(procedure => {
				let proceduresObj = {
					name: procedure.object.name,
					description: procedure.object.description,
				};
				procedures.push(proceduresObj);
			});
			if (procedures.length) {
				allProcedures = procedures;
			} 
		}
		newDatum.services = allConditions.concat(allProcedures);
		if (newDatum.services.length > 1) {
			newDatum.services.sort((a, b) => {
				let fa = a.name.toLowerCase(),
					fb = b.name.toLowerCase();

				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			});
		}

		// Parse out the specialty providers
		if (data.Provider && data.Provider.length) {
			let providers = [];
			data.Provider.forEach(provider => {
				let providerObj = {
					firstName: provider.object.firstName,
					lastName: provider.object.lastName,
					middleName: provider.object.middleName,
					nickName: provider.object.nickName,
					title: provider.object.abbreviations ? provider.object.abbreviations.join(', ') : null,
					category: provider.object.Category[0].object.name,
					specialty: ['Physician'],
					photo: provider.object.photo,
					link: provider.object.uri,
					phone: provider.object.directPhone,
					fax: provider.object.fax,
					id: provider.object.id,
				};
				this.allProviders.forEach(item => {
					let randSort = Math.floor(Math.random() * 10000);
					if (item.node.randSort) {
						randSort = item.node.randSort;
					}
					if (item.node.id === provider.object.id) {
						providerObj.randSort = randSort;
					}
				});
				if (provider.object.Specialty && provider.object.Specialty.length) {
					let specialties = [];
					provider.object.Specialty.forEach(specialty => {
						if (specialty.object.id) {
							if (this.allSpecialties.length) {
								this.allSpecialties.forEach(item => {
									if (item.node.id === specialty.object.id) {
										specialties.push(item.node.name);
									}
								});
							}
						}
					});
					if (specialties.length) {
						specialties.sort();
						providerObj.specialty  = specialties;
					}
				}
				if (provider.object.Category && provider.object.Category.length) {
					let categories = [];
					provider.object.Category.forEach(provCat => {
						if (provCat.object.id) {
							if (this.allCategories.length) {
								this.allCategories.forEach(item => {
									if (item.node.id === provCat.object.id) {
										categories.push(item.node.name);
									}
								});
							}
						}
					});
					if (categories.length) {
						providerObj.category  = categories[0];
					}
				}
				providers.push(providerObj);
			});
			if (providers.length) {
				function shuffleArray(array) {
					array.sort((left, right) => (left.randSort - right.randSort));
					return array;
				}
				providers = shuffleArray(providers);
				if (providers.length > 8) {
					providers.length = 8;
				}
				newDatum.providers = providers;
			} else {
				newDatum.providers = [];
			}
		}
		// Parse out the specialty locations
		if (data.Location && data.Location.length) {
			let locations = [];
			data.Location.forEach(location => {
				let locObject = {
					id: location.object.id,
					name: location.object.name,
					address: location.object.address1,
					city: location.object.city,
					state: location.object.state,
					zip: location.object.zip,
					phone: location.object.phone,
					uri: location.object.uri,
					fax: null,
					floor: null,
					hours: {},
				}
				// if (location.object.hoursMon) { locObject.hours.Mo = location.object.hoursMon };
				// if (location.object.hoursTue) { locObject.hours.Tu = location.object.hoursTue };
				// if (location.object.hoursWed) { locObject.hours.We = location.object.hoursWed };
				// if (location.object.hoursThu) { locObject.hours.Th = location.object.hoursThu };
				// if (location.object.hoursFri) { locObject.hours.Fr = location.object.hoursFri };
				// if (location.object.hoursSat) { locObject.hours.Sa = location.object.hoursSat };
				// if (location.object.hoursSun) { locObject.hours.Su = location.object.hoursSun };
				if (location.object.id) {
					if (this.allLocations.length) {
						this.allLocations.forEach(item => {
							if (item.node.id === location.object.id) {
								if (item.node.Specialty.length) {
									item.node.Specialty.forEach(specialtyItem => {
										if (specialtyItem.object.id === data.id) {
											if (specialtyItem.properties) {
												if (specialtyItem.properties.floor && specialtyItem.properties.floor !== '') {
													locObject.floor = specialtyItem.properties.floor;
												}
											}
										}
									})
								}
							}
						});
					}
				}
				locations.push(locObject);
			});
			if (locations.length) {
				newDatum.locations = locations;
			} else {
				newDatum.locations = null;
			}
		}
		return newDatum;
	}

	componentDidMount() {
		this.liveRefresh();
		if (Object.keys(this.state.data.callout).length === 0) {
			if (this.state.data.background === 'rgb(255,255,255)' && this.state.data.providers.length > 0) {
				this.setState({
					showHR: true,
				})
			}
		}
	}

	render() {
		let locations = this.state.data.locations;
		let services = this.state.data.services;
		return (
			<>
				<Layout>
					<Masthead sidebarImage={this.state.data.sidebarImage} sidebar={this.state.data.sidebar} background={this.state.data.background} name={this.state.data.name} description={this.state.data.description} />
					{this.state.showHR && <hr />}
					{Object.keys(this.state.data.callout).length > 0 && <Callout data={this.state.data.callout} />}
					{this.state.data.providers.length > 0 && <ProviderList data={this.state.data.providers} title={`Our ${this.state.data.name} Providers`} specialty={this.state.data.name} />}
					{locations.length > 0 && <LocationList data={locations} title={`${this.state.data.name} Locations`} />}
					<div style={{
						marginTop: '70px'
					}} />
					{services.length > 0 && <Fragment><Services closeText={`All ${this.state.data.name} Services`} data={services} title={`${this.state.data.name} Services`} /><hr /></Fragment>}
					<PatientEducationController tags={this.state.data.tags} />
					<DepartmentNewsController tags={this.state.data.tags} />
					{/*<FAQsController />*/}
					<SEO title={`${this.state.data.name} | ${this.props.data.site.siteMetadata.title}`}/>
				</Layout>
			</>
		)
	}
}

export const query = graphql`
	query ($uri: String) {
	  physiciansSpecialty(uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}) {
        id
        uri
        name
        description
        sidebar
        backgroundColor
        sidebarImage
        calloutBackgroundImage
        calloutBody
        calloutImage
        calloutTitle
        tags
        Location {
          object {
            id
            name
            address1
            address2
            city
            state
            zip
            uri
            hoursMon
            hoursTue
            hoursWed
            hoursThu
            hoursFri
            hoursSat
            hoursSun
          }
        }
        Provider {
          object {
          	id
	      	firstName
	      	lastName
	      	middleName
	      	nickName
	      	photo
	      	abbreviations
	      	uri
	      	photo
	      	directPhone
	      	Category {
	      	  object {
	      	  	id
	      	  }
	      	}
	      	Specialty {
	      	  object {
	      	  	id
	      	  }
	      	}
          }
        }
        Procedure {
          object {
          	name
          	description
          }
        }
        Condition {
          object {
          	name
          	description
          }
        }
      }
	  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        name
	      }
	    }
	  }
	  allPhysiciansCategory(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	      }
	    }
	  }
	  allPhysiciansProvider(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        randSort
	      }
	    }
	  }
	  allLocationsLocation {
	    edges {
	      node {
	        id
	        name
	        Specialty {
	          properties {
	            floor
	            phone
	          }
	          object {
	            id
	            name
	          }
	        }
	      }
	    }
	  }
	  site {
	    siteMetadata {
	      title
	    }
	  }
	}
`