import React, {Component, Fragment} from 'react'
import style from './services.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Icon from 'components/icon'
import Link from 'shared/link';
import ServiceSlider from './serviceSlider';
import { Transition, animated } from 'react-spring/renderprops';

class Services extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			service: null,
			data: [],
			services: [],
			forceUpdate: false,
		}
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}
	openModal(index) {
		this.setState({
			modal: true,
			service: index,
		});
	}
	closeModal() {
		this.setState({
			modal: false
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.forceUpdate !== prevState.forceUpdate) {
			this.forceUpdate();
		}
	}

	componentDidMount() {
		this.setState({
			forceUpdate: true,
		})
		if (this.props.data) {
			this.props.data.forEach((item, index) => {
				item.modalIndex = null;
				if (item.description) {
					this.state.services.push(item);
				}
				this.state.data.push(item);
			});
			if (this.state.services.length) {
				this.state.services.forEach((item, index) => {
					item.modalIndex = index;
				});
			}
		}
		if (this.state.data.length && this.state.services.length) {
			this.state.data.forEach((datum, index) => {
				if (datum.description) {
					this.state.services.forEach(item => {
						if (item.name === datum.name) {
							datum.modalIndex = item.modalIndex;
						}
					})
				}
			})
		}
	}

	render() {
		const modal = this.state.modal;
		return(
			<Fragment>
				<div className={[style.container, "grid-container"].join(' ')}>
					<div className="grid-x">
						<div className="cell">
							<h3 className={style.title}>{(this.props.title) ? this.props.title : 'Services'}</h3>
						</div>
					</div>
					<div className={[style.specialtiesGrid, "grid-x medium-up-2"].join(' ')}>
						{this.state.data.map((item, index) => (
							<div role="presentation" onClick={item.description ? () => this.openModal(item.modalIndex) : null} key={index} className={[style.specialty, 'cell'].join(' ')}>
								<div className={[item.description ? style.hover : '', style.inner].join(' ')}>{item.name}{item.description ? <Icon className={style.arrow} icon="rightArrow" /> : ''}</div>
							</div>
						))}
					</div>
				</div>
				<Transition
					native
					items={modal}
					from={{opacity: 0, left: '-100%'}}
					enter={{opacity: 1, left: '0'}}
					leave={{opacity: 0, left: '-100%'}}>
					{modal => modal && (props =>
						<animated.div style={props} className={style.modal}>
							<div className={`grid-container ${style.innerModal}`}>
								<div className={`grid-x grid-padding-x`}>
									<div className={`cell`}>
										<ServiceSlider closeText={this.props.closeText} clickHandle={this.closeModal} services={this.state.services} slide={this.state.service} />
									</div>
								</div>
							</div>
						</animated.div>
						)
					}
				</Transition>
			</Fragment>
		)
	}
}

export default Services;