import React, {Component} from 'react'
// Import neccessary site components, try to maintain alphabetical order
import Specialties from 'components/specialties/';


class ServicesListController extends Component {
	constructor(props) {
		super(props);

		this.data = [
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
			{
				title: 'Service Name',
				link: '/',
			},
		]
	}	

	render() {
		return (
			<Specialties data={this.data} title="Services" />
		);
	}
}

export default ServicesListController;