import React, {Component} from 'react';
import Background from 'shared/background';
import Image from 'shared/image';
import parse from 'html-react-parser';
import style from './callout.module.scss';

class Callout extends Component {
	render() {
		const {title, image, body, background} = this.props.data;
		return (
			<section className={style.wrapper} style={{'backgroundColor':'rgb(243,243,243)'}}>
				<Background className={style.background} image={background}>
					<div className={[style.container, "grid-container"].join(' ')}>
						<div className="grid-x">
							<div className={[image ? 'medium-7' : 'medium-12', style.content, "cell"].join(' ')}>
								<h3 className={style.title}>{title}</h3>
								<div className={style.body}>
									{body ? parse(body) : ''}
								</div>
							</div>
							<div className={[image ? 'cell medium-5' : 'hide'].join(' ')}>
								<div className={style.sidebarImage}>
									{image ? <Image src={image} /> : ''}
								</div>
							</div>
						</div>
					</div>
				</Background>
			</section>
		)
	}
}

export default Callout;